import { CohortEventInstanceStatus } from "@generated/graphql";
import { IANAtzName, LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import {
  assertCalendarEventCohortInstance,
  CalendarEventInfo,
  DEFAULT_EVENT_COLOR,
  EVENT_COLORS,
} from "components/weekCalendar";
import uniq from "lodash/uniq";
import { useMemo } from "react";
import { CalendarEvent } from "./components/CalendarEvent/CalendarEvent";
import { positionEvents } from "./utils";

type Props = {
  calendarEventClassName?: string;
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  eventInfos: CalendarEventInfo[];
  locale: string;
  viewingTimeZone: IANAtzName;
  mode24Hour: boolean;
  refetch?: () => void;
};

export const CalendarEvents = ({
  calendarEventClassName,
  localizedWeekdays,
  focusedWeekday,
  eventInfos,
  locale,
  viewingTimeZone,
  mode24Hour,
  refetch,
}: Props) => {
  const uniqueEventKeys = uniq(eventInfos.map((e) => e.groupKey));

  const positionedEventInfos = useMemo(
    () => positionEvents(eventInfos),
    [eventInfos]
  );

  return positionedEventInfos.map((posEventInfo) => {
    const groupNumber = uniqueEventKeys.findIndex(
      (key) => key === posEventInfo.groupKey
    );
    const eventColor =
      assertCalendarEventCohortInstance(posEventInfo) &&
      posEventInfo.status === CohortEventInstanceStatus.Cancelled
        ? DEFAULT_EVENT_COLOR
        : EVENT_COLORS[groupNumber % EVENT_COLORS.length];

    return (
      <CalendarEvent
        key={posEventInfo.key}
        className={calendarEventClassName}
        eventInfo={{
          ...posEventInfo,
          eventColor,
        }}
        localizedWeekdays={localizedWeekdays}
        focusedWeekday={focusedWeekday}
        locale={locale}
        mode24Hour={mode24Hour}
        viewingTimeZone={viewingTimeZone}
        refetch={refetch}
      />
    );
  });
};
