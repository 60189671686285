import {
  Cohort,
  CohortEvent,
  CohortEventInstanceStatus,
  CohortSession,
  Engagement,
  Holiday,
} from "@generated/graphql";
import { IANAtzName, LocalizedWeekday } from "@utils/dateTime";
import { Percent } from "@utils/numbers";
import { IconType } from "components/shared/Icon";
import { ReactElement } from "react";

export enum CalendarDisplayMode {
  Responsive = "Responsive",
  AlwaysMobile = "AlwaysMobile",
}

export type EventColor = {
  bg: string;
  bgHover: string;
  text: string;
  border: string;
  textHover: string;
  accent: string;
};

export type ContentProps = {
  eventColor?: EventColor;
};

export type ContentFunction = (props: ContentProps) => ReactElement;

export enum CalendarEventType {
  CohortEventInstance = "CohortEventInstance",
  EngagementEventAggregate = "EngagementEventAggregate",
}

type CalendarEventBaseInfo = {
  type: CalendarEventType;
  isoStartFloatingDateTime: string;
  startDateTime: Date;
  timeZone: IANAtzName;
  durationMinutes: number;
  endDateTime: Date;

  key: string;
  groupKey: string;
  title: string;
  details?: string;
  content?: ContentFunction;
  charmIcon?: IconType;
  eventColor?: EventColor;
};

export type CalendarEventCohortInstanceInfo = CalendarEventBaseInfo & {
  type: CalendarEventType.CohortEventInstance;

  cohortStartDate: Date;
  cohortEndDate: Date;
  cohortEventId: CohortEvent["id"] | null;
  cohortSessionId: CohortSession["id"] | null;
  cohortName: string;
  cohortId: Cohort["id"];
  status?: CohortEventInstanceStatus | null;
  onEngagementShift?: boolean;
};

export type CalendarEventEngagementAggregateInfo = CalendarEventBaseInfo & {
  type: CalendarEventType.EngagementEventAggregate;

  engagementId: Engagement["id"];
  engagementStartDate: Date;
  engagementEndDate: Date;
  events: {
    cohortId: Cohort["id"];
    cohortName: Cohort["name"];
    cohortSubject: CohortEvent["cohortSubject"];
    cohortSubSubject: CohortEvent["cohortSubSubject"];
    status?: CohortEventInstanceStatus | null;
  }[];
};

export type CalendarEventInfo =
  | CalendarEventCohortInstanceInfo
  | CalendarEventEngagementAggregateInfo;

export type CalendarEventInfoPositioned = CalendarEventInfo & {
  left: Percent;
  width: Percent;
  zIndex: number;
};

export function assertCalendarEventCohortInstance(
  eventInfo: CalendarEventInfo
): eventInfo is CalendarEventCohortInstanceInfo {
  return eventInfo.type === CalendarEventType.CohortEventInstance;
}

export function assertCalendarEventEngagementAggregateInfo(
  eventInfo: CalendarEventInfo
): eventInfo is CalendarEventEngagementAggregateInfo {
  return eventInfo.type === CalendarEventType.EngagementEventAggregate;
}

export type CalendarNavHolidayData = Pick<
  Holiday,
  "id" | "type" | "name" | "startDate" | "endDate"
> & {
  organizationName?: string;
  dayNumber: number;
  daysCount: number;
};

export type CalendarNavData = {
  localizedWeekday: LocalizedWeekday;
  eventInfos: CalendarEventCohortInstanceInfo[];
  navHolidays: CalendarNavHolidayData[];
};
