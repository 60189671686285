import { gql } from "@apollo/client";
import {
  AccountRole,
  AccountStatus,
  CalendarEventCohortDetails_CohortFragment,
} from "@generated/graphql";
import { getAccountRoleText, getCohortAssignmentRoleText } from "@utils/labels";
import { DEFAULT_EVENT_COLOR } from "components/weekCalendar";
import { ContentProps } from "../types";
import { MeetingLink } from "./MeetingLink";

CalendarEventCohortDetails.fragments = {
  cohort: gql`
    fragment CalendarEventCohortDetails_Cohort on Cohort {
      id
      staffAssignments {
        user {
          id
          accountRole
          fullName
          accountStatus
        }
        cohortSubject
        cohortAssignmentRole
      }
      ...MeetingLink_Meeting_Cohort
    }
    ${MeetingLink.fragments.meetingCohort}
  `,
};

type Props = ContentProps & {
  cohort: CalendarEventCohortDetails_CohortFragment;
  // Heads up: staffAssignments are filtered by parent component.
  filteredStaffAssignments: CalendarEventCohortDetails_CohortFragment["staffAssignments"];
};

export function CalendarEventCohortDetails({
  cohort,
  filteredStaffAssignments,
  eventColor = DEFAULT_EVENT_COLOR,
}: Props) {
  return (
    <div className="flex flex-col gap-2">
      {filteredStaffAssignments.length > 0 ? (
        filteredStaffAssignments.map((staffAssignment) => (
          <div key={staffAssignment.user.id} className="flex flex-col">
            <p className={`text-sm text-semibold ${eventColor?.text}`}>
              {staffAssignment.user.fullName}
              {staffAssignment.user.accountRole !==
                AccountRole.TutorTeacher && ( // Provide visibility for MTs and Admins
                <span className="font-normal italic">
                  {` (${getAccountRoleText(staffAssignment.user.accountRole)})`}
                </span>
              )}
              {staffAssignment.user.accountStatus ===
                AccountStatus.Inactive && (
                <span className="text-red-500 font-semibold"> (Inactive)</span>
              )}
            </p>
            <p className="text-xs italic text-gray-400">
              <span>{getCohortAssignmentRoleText("long")}</span>
            </p>
          </div>
        ))
      ) : (
        <p className="text-sm text-semibold">
          No staff has been assigned to this subject.
        </p>
      )}

      <MeetingLink cohort={cohort} eventColor={eventColor} />
    </div>
  );
}
