import { DAY_MIN, localizedTime } from "@utils/dateTime";
import { Fragment } from "react";
import { RULE_RESOLUTION_MIN } from "../constants";

type HourLinesProps = {
  locale: string;
  mode24Hour: boolean;
};
export const HourLines = ({ locale, mode24Hour }: HourLinesProps) => {
  const gridCount = DAY_MIN / RULE_RESOLUTION_MIN;
  const filler = Array(Math.max(0, Math.floor(gridCount / 24) - 1)).fill(0);
  const hourLabels = Array(24)
    .fill(0)
    .map((_, h) => `${String(h).padStart(2, "0")}:00`);
  return (
    <>
      {hourLabels.map((hour) => (
        <Fragment key={hour}>
          <div>
            <div className="sticky whitespace-nowrap left-0 z-20 -mt-2.5 -ml-14 w-14 pr-1 text-right text-xs leading-5 text-gray-400">
              {localizedTime(hour, mode24Hour, locale)}
            </div>
          </div>
          {filler.map((_, i) => (
            <div key={i} />
          ))}
        </Fragment>
      ))}
    </>
  );
};
