import { DateInfo, localizedTime } from "@utils/dateTime";
import clsx from "clsx";
import { Icon } from "components/shared";
import { CalendarEventInfo } from "components/weekCalendar";

type Props = {
  eventInfo: CalendarEventInfo;
  startInfo: DateInfo;
  locale: string;
  mode24Hour: boolean;
};

const CalendarEventDetails = ({
  eventInfo,
  startInfo,
  mode24Hour,
  locale,
}: Props) => (
  <div
    className={clsx(
      "group absolute inset-px flex flex-col overflow-hidden rounded-lg outline outline-1 outline-offset-0 outline-white",
      "p-2 lg:pt-1 lg:pl-1.5 text-xs leading-5",
      eventInfo.eventColor?.bg,
      eventInfo.eventColor?.bgHover
    )}
  >
    <p
      className={clsx(
        "flex flex-row justify-between",
        eventInfo.eventColor?.text,
        eventInfo.eventColor?.textHover
      )}
    >
      <time dateTime={`${startInfo.isoDate}T${startInfo.time}`}>
        {localizedTime(startInfo.time, mode24Hour, locale)}
      </time>
      {eventInfo.charmIcon && (
        <Icon
          icon={eventInfo.charmIcon}
          className="w-4 h-4 shrink-0"
          color={eventInfo.eventColor?.text}
        />
      )}
    </p>
    <p className={`font-semibold leading-tight ${eventInfo.eventColor?.text}`}>
      {eventInfo.title || "Untitled Event"}
    </p>
    {eventInfo.details && (
      <p className={`font-normal leading-tight ${eventInfo.eventColor?.text}`}>
        {eventInfo.details}
      </p>
    )}
  </div>
);

export default CalendarEventDetails;
