import { Button } from "components/shared";
import { CalendarEventCohortInstanceInfo } from "components/weekCalendar";
import pluralize from "pluralize";
import { useCancelEvents } from "../../CalendarEvents/hooks";

type Props = {
  cancellableEventInstanceInfos: CalendarEventCohortInstanceInfo[];
  className?: string;
  buttonText?: string;
  onSuccess?: () => void;
  refetch?: () => void;
};

export function CancelAllCalendarEventsButton({
  cancellableEventInstanceInfos,
  className,
  buttonText,
  onSuccess,
  refetch,
}: Props) {
  const cancelEvents = useCancelEvents({
    refetch,
    onSuccess,
    cancellableEvents: cancellableEventInstanceInfos,
  });

  return cancellableEventInstanceInfos.length !== 0 ? (
    <Button
      onClick={cancelEvents}
      theme="danger"
      className={className}
      disabled={cancellableEventInstanceInfos.length === 0}
    >
      {buttonText ??
        `Cancel ${pluralize(
          "Event",
          cancellableEventInstanceInfos.length,
          true
        )}`}
    </Button>
  ) : null;
}
