import { IANAtzName, LocalizedWeekday, WeekdayNumber } from "@utils/dateTime";
import clsx from "clsx";
import { CalendarEventInfoPositioned } from "components/weekCalendar";
import { Popover } from "components/weekCalendar/components/Popover";
import {
  GRID_RESOLUTION_MIN,
  MINIMUM_DISPLAYED_LENGTH_MIN,
} from "components/weekCalendar/constants";
import { useState } from "react";
import { getDateData } from "../../utils";
import { WEEK_COL_START_CLASSES } from "../constants";
import CalendarEventDetails from "./components/CalendarEventDetails";
import { CalendarEventPopover } from "./components/CalendarEventPopover";

type Props = {
  className?: string;
  eventInfo: CalendarEventInfoPositioned;
  localizedWeekdays: LocalizedWeekday[];
  focusedWeekday: WeekdayNumber;
  locale: string;
  mode24Hour: boolean;
  viewingTimeZone: IANAtzName;
  refetch?: () => void;
};

export const CalendarEvent = ({
  className,
  eventInfo,
  localizedWeekdays,
  focusedWeekday,
  locale,
  mode24Hour,
  viewingTimeZone,
  refetch,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const startInfo = getDateData(eventInfo, viewingTimeZone);
  const startGridRow =
    Math.floor(startInfo.minutesElapsedInDay / GRID_RESOLUTION_MIN) + 2;
  const gridSpan = Math.max(
    Math.ceil(eventInfo.durationMinutes / GRID_RESOLUTION_MIN),
    Math.ceil(MINIMUM_DISPLAYED_LENGTH_MIN / GRID_RESOLUTION_MIN)
  );

  return (
    <li
      className={clsx(
        className,
        "relative mt-px min-h-[50px] cursor-pointer",
        startInfo.weekdayNumber !== focusedWeekday && "hidden",
        WEEK_COL_START_CLASSES[startInfo.weekdayNumber]
      )}
      style={{
        gridRow: `${startGridRow} / span ${gridSpan}`,
        left: `${eventInfo.left}%`,
        width: `${eventInfo.width}%`,
        zIndex: isFocused ? 100 : isHovered ? 99 : eventInfo.zIndex,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Popover
        placement="top-start"
        render={() => (
          <CalendarEventPopover
            eventInfo={eventInfo}
            localizedWeekdays={localizedWeekdays}
            locale={locale}
            mode24Hour={mode24Hour}
            viewingTimeZone={viewingTimeZone}
            refetch={refetch}
          />
        )}
        onOpen={() => setIsFocused(true)}
        onClose={() => setIsFocused(false)}
      >
        <div>
          <CalendarEventDetails
            eventInfo={eventInfo}
            startInfo={startInfo}
            mode24Hour={mode24Hour}
            locale={locale}
          />
        </div>
      </Popover>
    </li>
  );
};
